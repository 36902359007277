import { Button, IconButton, Tooltip } from "@mui/material";
import React from "react";
import { BiLogOut, BiLogIn } from "react-icons/bi";


import keycloak from "../../keycloak";
import "./UserLabel.scss";
import { useNavigate } from "react-router-dom";

const UserLabel = (props) => {
  const navigate = useNavigate();

  const logout = () => {
    
    keycloak.logout();
    navigate("/");
  }

  if(keycloak.authenticated){
    return (
      <div className="user-container">
        <div className="black-text email-text">
          <span>{props.visibleName ? keycloak.idTokenParsed.email : ""}</span>
        </div>
  
        <div className="button">
          <div className="log-out-button">
            <Tooltip title="Log out">
              <IconButton aria-label="" onClick={() => {logout()}}>
                <BiLogOut className="icon" />
              </IconButton>
            </Tooltip>
          </div>
        </div>
      </div>
    );
  } else {
    return(
        <div className="user-container">
        <Button variant="contained" className="login" onClick={keycloak.login} endIcon={<BiLogIn />}>
          login
        </Button>
      </div>
    )
  }
  
};

export default UserLabel;
