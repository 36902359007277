import Modal from "react-modal";
import "./ImageModal.scss";

export const ImageModal = ({ setOpen, open, src }) => {
  return (
    <Modal
      isOpen={open}
      onRequestClose={() => setOpen(false)}
      ariaHideApp={false}
      style={{
        content: {
          position: "initial",
        },
        overlay: {
          position: "fixed",
          zIndex: 1020,
          top: 0,
          left: 0,
          width: "100vw",
          height: "100vh",
          background: "rgba(255, 255, 255, 0.75)",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        },
      }}
    >
      <div className="image-modal-container">
        <img className="image-content" src={src} alt="" />
      </div>
    </Modal>
  );
};
