import { useEffect, useState } from "react";
import { GiHamburgerMenu } from "react-icons/gi";
import { MdClose } from "react-icons/md";
import { Link, useLocation, useParams } from "react-router-dom";
import BlueBucketIcon from "../../assets/icon/bucket-icon-blue.svg";
import WhiteBucketIcon from "../../assets/icon/bucket-icon-white.svg";
import useModal from "../../hooks/useModal";
import useS3 from "../../hooks/useS3";
import keycloak from "../../keycloak";
import UserLabel from "../userLabel/UserLabel";
import myImage from "./../../assets/logo.svg";
import "./Sidebar.scss";

const Sidebar = () => {
  const location = useLocation();
  const [buckets, setBuckets] = useState([]);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { showModal } = useModal();
  const { getBucketS3 } = useS3();

  let { id } = useParams();

  useEffect(() => {
    // if(keycloak.authenticated){
      getBucketS3()
        .then((res) => {
          setBuckets(res);
        })
        .catch((err) => {
          //console.log(err, err.stack);
        });
    // } else {

    // }
  }, [location]);


  return (
    <div>
      {/* SIDEBAR */}
      <div className="sidebar">
        <img
          src={myImage}
          className="sidebar__logo"

          alt="logo"
        />
        <div className="sidebar__menu">
          {buckets.map((item, index) => (
            <Link to={`${item.Name}`} key={index}>
              <div
                className={`sidebar__menu__item ${
                  item.Name === id ? "active" : ""
                } pointer`}
              >
                <div className="sidebar__menu__item__icon">
                  <img
                    className="icon"
                    src={item.Name === id ? BlueBucketIcon : WhiteBucketIcon}
                    alt=""
                  />
                </div>
                <div className="sidebar__menu__item__text">{item.Name}</div>
              </div>
            </Link>
          ))}
        </div>
      </div>
      {/* NAVBAR */}
      <div className="topbar">
        {/* {SIDEBAR} */}
        <div className="navbar">
          <div className="hamburger" onClick={() => setIsMenuOpen(!isMenuOpen)}>
            {!isMenuOpen ? (
              <GiHamburgerMenu></GiHamburgerMenu>
            ) : (
              <MdClose></MdClose>
            )}
          </div>
          <div className="logo">
            <img src={myImage} alt="" />
          </div>
          <div className="user">
            <UserLabel visibleName={false}></UserLabel>
          </div>
        </div>
        {/* {SIDEBAR} */}
        {isMenuOpen ? (
          /* NAVBAR */
          <div className="menu-topbar">
            {buckets.map((item, index) => (
              <Link
                to={`${item.Name}`}
                key={index}
                onClick={() => setIsMenuOpen(!isMenuOpen)}
              >
                <div
                  className={`sidebar__menu__item ${
                    item.Name === id ? "active" : ""
                  }`}
                >
                  <div className="sidebar__menu__item__icon">
                    <img
                      className="icon"
                      src={item.Name === id ? BlueBucketIcon : WhiteBucketIcon}
                      alt=""
                    />
                  </div>
                  <div className="sidebar__menu__item__text">{item.Name}</div>
                </div>
              </Link>
            ))}
          </div>
        ) : null}
        {/* NAVBAR */}
      </div>
    </div>
  );
};

export default Sidebar;
