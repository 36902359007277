/* eslint-disable array-callback-return */
import { Button, CircularProgress, IconButton, Tooltip } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import CheckIconSvg from "../../assets/icon/check-icon-blue.svg";
import { ReactComponent as DeleteIcon } from "../../assets/icon/delete-selection-icon-white.svg";
import { ReactComponent as DownloadIconWhite } from "../../assets/icon/download-icon-white.svg";
import NotCheckIconSvg from "../../assets/icon/empty-selection-icon-blue.svg";
import keycloak from "../../keycloak";
import pathUrlManager from "../../utils/pathUrlManager";
import FilePreviewGenerator from "../filePreviewGenerator/filePreviewGenerator";

import { FiEdit2 } from 'react-icons/fi';
import { BsCardImage } from 'react-icons/bs';

export const File = ({
  file,
  setCardHover,
  downloadFile,
  deleteFile,
  viewMode,
  deleteModal,
  returnDate,
  checkedFile,
  readOnly,
  editName
}) => {
  const MyComponent = useRef(null);

  
  useEffect(() => {
    import("../filePreviewGenerator/filePreviewGenerator").then((component) => {
      MyComponent.current = component.default;
    });
    
    
  }, []);

  return viewMode &&
    pathUrlManager.fileNameCleaner(file.Key).charAt(0) !== "." ? (
    <div className="card-cover">
      <div
        className="card p-5"
        onMouseOver={() => setCardHover(true)}
        onMouseLeave={() => setCardHover(false)}
      >
        <div className="selector">
          {file.checked ? (
            <img
              src={CheckIconSvg}
              className="checkbox"
              onClick={() => checkedFile(file.Key, file.checked)}
              alt="checked icon"
            />
          ) : (
            <img
              src={NotCheckIconSvg}
              className="checkbox"
              onClick={() => checkedFile(file.Key, file.checked)}
              alt="not checked icon"
            />
          )}

          {!!keycloak.authenticated && !!file.tumb ? (
              <Tooltip title="No thumbnail">
                  <IconButton aria-label="">
                    <BsCardImage style={{fontSize: "36px", color:"orange"}}></BsCardImage>
                  </IconButton>
              </Tooltip>
          ) : (null)}
        
          
        </div>
        <div className="preview">
          {file.preview && MyComponent.current ? 
          <div style={{position: "relative",width: "100px",height: "100px"}}>
            <div style={{position: "absolute",width: "100px",height: "100px", display: "flex",alignItems: "center",justifyContent:"center"}}>
              <MyComponent.current
                fileType={file.Key}
                url={file.preview}
                preview={true}
              /> 
            </div>
            <div style={{ width: "100%", height: "100%", display: "flex", alignItems: "center", justifyContent: "center"}}>
              <MyComponent.current
                fileType={file.Key}
                url={file.preview2}
                preview={true}
              /> 
            </div>
          </div>
          : <CircularProgress color="inherit" />}
        </div>
        <div className="title">
          <div className="text">{pathUrlManager.fileNameCleaner(file.Key)}</div>
        
        </div>
        <div className="action">
          <Button
            variant="contained"
            onClick={() => downloadFile(file.Key)}
            startIcon={<DownloadIconWhite className="icon-size-small" />}
            className="primary-button blue-active-bg max-width"
          >
            Download
          </Button>
          {!!keycloak.authenticated &&
            (<Button
              variant="contained"
              onClick={() => deleteModal(file.Key, deleteFile)}
              startIcon={<DeleteIcon className="icon-size-small" />}
              style={{ marginTop: readOnly ? "3%" : "" }}
              className={`primary-button danger-bg max-width ${
                readOnly ? "" : "read-only"
              }`}
            >
              Delete
            </Button>
          )}
           {!!keycloak.authenticated &&
            (<Button
              variant="contained"
              onClick={() => editName(file.Key, false)}
              startIcon={<FiEdit2 className="icon-size-small" />}
              style={{ marginTop: readOnly ? "3%" : "" }}
              className={`primary-button warning-bg max-width ${
                readOnly ? "" : "read-only"
              }`}
            >
              Rename
            </Button>
          )}
         
        </div>
      </div>
    </div>
  ) : (
    file && pathUrlManager.fileNameCleaner(file.Key).charAt(0) !== "." && (
      <div className="table-row white-table-row">
        <div className="name-header">
          <div className="check-list-container">
            {file.checked ? (
              <img
                src={CheckIconSvg}
                className="checkbox pointer"
                onClick={() => checkedFile(file.Key, file.checked)}
                alt="checked icon"
              />
            ) : (
              <img
                src={NotCheckIconSvg}
                className="checkbox pointer"
                onClick={() => checkedFile(file.Key, file.checked)}
                alt="not checked icon"
              />
            )}
          </div>
          <div className="check-list-container">
            <FilePreviewGenerator
              folder={false}
              fileType={file.Key}
              url={file.preview}
              preview={false}
            />
          </div>
          <div className="text-header medium-text black-text">
            {pathUrlManager.fileNameCleaner(file.Key)}
          </div>
        </div>
        <div className="size-header medium-text black-text">
          {file.Size / 1000} KB
        </div>
        <div className="modify-header medium-text black-text">
          {returnDate(file)}
        </div>
        <div className="download-header">
        
          <IconButton
            variant="contained"
            style={{ marginRight: "5px" }}
            onClick={() => downloadFile(file.Key)}
            className="square-primary-button blue-active-bg"
          >
            <DownloadIconWhite className="icon-size-small" />
          </IconButton>
          {!!keycloak.authenticated && (
          <IconButton
            variant="contained"
            onClick={() => deleteModal(file.Key, deleteFile)}
            className={`square-primary-button danger-bg ${
              readOnly ? "" : "read-only"
            }`}
          >
            <DeleteIcon className="icon-size-small " />
          </IconButton>)}
        </div>
      </div>
    )
  );
};
