/* eslint-disable no-loop-func */
/* eslint-disable array-callback-return */
import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { cardHoverState } from "../../recoil/atom/cardHoverState";
import { loaderState } from "../../recoil/atom/loaderState";
import axios from "axios";
import { Alert, Button, Fab, IconButton, Snackbar } from "@mui/material";

import UserLabel from "../../components/userLabel/UserLabel";
import useAddFile from "../../hooks/useAddFile";
import useDate from "../../hooks/useDate";
import useFile from "../../hooks/useFile";
import useModal from "../../hooks/useModal";
import useS3 from "../../hooks/useS3";

import { File as FileTest } from "../../components/File/File";
import { Folder } from "../../components/Folder/Folder";
import Loader from "../../components/loader/Loader";
import pathUrlManager from "../../utils/pathUrlManager";

import { ReactComponent as AddIconWhite } from "../../assets/icon/add-icon-white.svg";
import { ReactComponent as AddFolderIcon } from "../../assets/icon/addfolder-icon-white.svg";
import { ReactComponent as CheckIconWhite } from "../../assets/icon/check-icon-white.svg";
import DeleteIconBlue from "../../assets/icon/delete-selection-icon-blue.svg";
import { ReactComponent as DeleteIcon } from "../../assets/icon/delete-selection-icon-white.svg";
import { ReactComponent as DownloadIconWhite } from "../../assets/icon/download-icon-white.svg";
import { ReactComponent as NotCheckIcon } from "../../assets/icon/empty-selection-icon-blue.svg";
import { ReactComponent as GridIcon } from "../../assets/icon/frame-icon-white.svg";
import { ReactComponent as ListIcon } from "../../assets/icon/list-icon-white.svg";
import SearchIconBlue from "../../assets/icon/search-icon-blue.svg";
import SlashIcon from "../../assets/icon/slash-icon-darkgray.svg";
import { BsCardImage } from 'react-icons/bs';

import { useCallback } from "react";
import useConfigPolicy from "../../hooks/useConfigPolicy";
import { keycloackPolicyState } from "../../recoil/atom/keycloackPolicyState";
import { reloadState } from "../../recoil/atom/reloadState";
import "./BucketViewer.scss";
import keycloak from "../../keycloak";


const BucketViewer = () => {
  const policy = useRecoilValue(keycloackPolicyState);
  const navigate = useNavigate();
  const [readOnly, setReadOnly] = useState(false);
  const [folder, setFolders] = useState([]);
  const [file, setFile] = useState([]);
  const [bucket, setBucket] = useState("");
  const setCardHover = useSetRecoilState(cardHoverState);
  const reload = useRecoilValue(reloadState);
  const [breadcrumb, setBreadcrumb] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [rename, setRename] = useState(false);
  const [renameInfo, setRenameInfo] = useState(null);
  const [renameValue, setRenameValue] = useState("");
  const [renameFolder, setRenameFolder] = useState("");
  const [viewMode, setViewMode] = useState(true);
  const [toast, setToast] = useState(false);
  const [viewLoader, setViewLoader] = useRecoilState(loaderState);
  const { showModal, hideModal } = useModal();
  const { fileStore } = useFile();
  const { view } = useAddFile();
  const {
    deleteFileS3,
    downloadFolderS3,
    getObjectsS3,
    getUrlFileS3,
    downloadAllFileS3,
    downloadFileS3,
    deleteFolderS3,
    deleteAllS3,
    getFileS3,
    getUrlFileS3ToPut,
    copyObjectsS3,
    copyFolder,
    getHeadFileS3
  } = useS3();
  const { returnDate } = useDate();
  const { initializePolicy } = useConfigPolicy();
  let { id, prefix, delimiter } = useParams();
  const location = useLocation();

  const params = {
    Bucket: id,
    Delimiter: delimiter ? delimiter : "/",
    Prefix: prefix ? pathUrlManager.prefixGenerator(location) : "",
  };

  const reloadFolder = () => {
    setViewLoader(true);
    setSearchText("");
    getObjectsS3(params)
      .then((res) => {
       
        setBreadcrumb(location.pathname.split("/").splice(1));
        setBucket(res.Name);
        //setFolders(res.CommonPrefixes.filter(x => x.Prefix !== "mf-tumbnail/"));
        setFolders(res.CommonPrefixes);
        res.Contents.map(async (el) => {
          el["bucket"] = params.Bucket;
          el["location"] = location.pathname.split("/").splice(1).join("/");
          el["checked"] = false;
          await getUrlFileS3(params.Bucket, el.Key.split("/").slice(0, -1).join("/") + '/mf-tumbnail/' + el.Key.split('/').pop())
          .then(async(res) => {
            el["preview"] = res;
          })
          await getUrlFileS3(params.Bucket, el.Key)
          .then((res) => {
            el["preview2"] = res;
          })
          if(pathUrlManager.getFileExtension(el.Key) == 'jpeg' || pathUrlManager.getFileExtension(el.Key) == 'jpg'|| pathUrlManager.getFileExtension(el.Key) == 'png' || pathUrlManager.getFileExtension(el.Key) == 'tif' || pathUrlManager.getFileExtension(el.Key) == 'tiff'){
            getHeadFileS3(params.Bucket, el.Key.split("/").slice(0, -1).join("/") + '/mf-tumbnail/' + el.Key.split('/').pop()).then((res) => {
              el["tumb"] = !res;
            })
          }
        });
       
        setFile(res.Contents);
        setViewLoader(false);
      })
      .catch((err) => {
        //console.log(err);
        setViewLoader(false);
      });
  };


  const generateThumbnail = (file, boundBox) => {
    if (!boundBox || boundBox.length != 2){
      throw "You need to give the boundBox"
    }
    const canvas = document.createElement("canvas")
    const ctx = canvas.getContext('2d')
    if (!ctx) {
      throw new Error('Context not available')
    }
  
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.onerror = reject
      img.onload = function() {
        const scaleRatio = Math.min(...boundBox) / Math.max(img.width, img.height)
        const w = img.width * scaleRatio
        const h = img.height * scaleRatio
        canvas.width = w
        canvas.height = h
        ctx.drawImage(img, 0, 0, w, h)
        return resolve(canvas.toDataURL(file.type))
      }
      img.src = window.URL.createObjectURL(file)
    })
  }

  const createAllThumbnail = async () => {
    
    await getObjectsS3(params)
    .then(async (res) => {
     
      for (let index = 0; index < res.Contents.length; index++) {

        let contentLength = res.Contents.length
        let element = res.Contents[index];
        console.warn("filename", element.Key)
        await getFileS3(params.Bucket, element.Key)
        .then(async (res) => {
        
          
          if(pathUrlManager.getFileExtension(element.Key) == 'jpeg' || pathUrlManager.getFileExtension(element.Key) == 'jpg'|| pathUrlManager.getFileExtension(element.Key) == 'png' || pathUrlManager.getFileExtension(element.Key) == 'tif' || pathUrlManager.getFileExtension(element.Key) == 'tiff'){
           
            await generateThumbnail(new File([new Blob([res])], 'mf-tumbnail/' + element.Key, {type: pathUrlManager.getFileExtension(element.Key)}), [100,100])
            .then(async (res) => {
              let test = dataURItoBlob(res);
              let el = new File([new Blob([test])], pathUrlManager.generateThumbnailPath(element.Key), {type: pathUrlManager.getFileExtension(element.Key)})
          
              await getUrlFileS3ToPut({
                Bucket: params.Bucket,
                Key: el.name,
                Expires: 60,
                ContentType: el.type,
              })
              .then(async (res) => {
                var axiosOptions = {
                  headers: {
                    "Content-Type": el.type,
                  },
                };
                
                await axios
                  .put(res, el, axiosOptions)
                  .then(() => {
                    console.warn('put iamge',index, contentLength, index + 1 == contentLength)
                  })
                  .catch((err) => {
                  });
              })
              .catch((err) => {
                //console.log("error getSignedUrl", err);
              });
            })
            .catch((err) => {
              console.log("error generate tumbnail", err);
            });
          }
        })
      }
      reloadFolder();
    })
  };

  const dataURItoBlob = (dataURI) => {
    // convert base64 to raw binary data held in a string
    // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
    var byteString = atob(dataURI.split(',')[1]);

    // separate out the mime component
    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

    // write the bytes of the string to an ArrayBuffer
    var ab = new ArrayBuffer(byteString.length);
    var ia = new Uint8Array(ab);
    for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }

    //Old Code
    //write the ArrayBuffer to a blob, and you're done
    //var bb = new BlobBuilder();
    //bb.append(ab);
    //return bb.getBlob(mimeString);

    //New Code
    return new Blob([ab], {type: mimeString});


}
 

  useEffect(() => {
    //if(keycloak.authenticated){
      reloadFolder();
      initializePolicy();
    //}
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, reload]);



  useEffect(() => {
    //if(keycloak.authenticated){
      if (policy && bucket) {
        if (policy[bucket] === true || policy['admin'] === true) {
          setReadOnly(true);
        } else {
          setReadOnly(false);
        }
      }
    //}
  }, [bucket, policy]);

  const downloadFile = async (key) => {
    downloadFileS3(bucket, key)
    .then((res) => {})
    .catch((err) => {
      //error("error deleteFile", err);
    });
  };

  const deleteFile = async (nameFile) => {
    console.warn('deleteFile', nameFile, params.Bucket)
    await deleteFileS3(nameFile, params.Bucket)
    .then(async (res) => {
      await deleteFileS3(pathUrlManager.generateThumbnailPath(nameFile), params.Bucket)
      .then((res) => {
        reloadFolder();
      })
      .catch((err) => {
        reloadFolder();
      });
    })
    .catch((err) => {
      reloadFolder();
    });
  };




  //TODO
  const downloadFolder = async (item) => {
    downloadFolderS3(item, bucket)
      .then((res) => {
        // console.warn(res);
      })
      .catch((err) => {
        //console.error("downloadFolder", err);
      });
  };

  const deleteFolder = async (item) => {
    deleteFolderS3(item, params.Bucket)
      .then(() => {
        reloadFolder();
      })
      .catch(() => {
        reloadFolder();
      });
  };

  const deleteAll = () => {
    setViewLoader(true);
    deleteAllS3(folder, file, params.Bucket)
    .then((res) => {
      reloadFolder()
    })
    .catch((err) => {
      reloadFolder();
    });
  };

  //TODO
  const downloadAllFile = async () => {
    downloadAllFileS3(id, folder, file).catch((log, err) => {
      //console.error("downloadAllFile", log, err);
      setToast(true);
    });
  };

  //CHECK FILE LOGIC
  const checkedFile = useCallback(
    (fileKey, status) => {
      let newStatus = !status;
      let newColl = [...file];
      newColl.find((file) => {
        if (file.Key === fileKey) {
          file.checked = newStatus;
        }
      });

      setFile(newColl);
    },
    [file]
  );

  const checkedFolder = useCallback(
    (item, status) => {
      let newStatus = !status;
      let newColl = [...folder];

      newColl.find((folder) => {
        if (folder.Prefix === item.Prefix) {
          folder.checked = newStatus;
        }
      });

      setFolders(newColl);
    },
    [folder]
  );

  const checkedAllFile = function () {
    let newColl = [...file];

    newColl.find((file) => {
      file.checked = true;
    });

    setFile(newColl);

    let newCollFolder = [...folder];

    newCollFolder.find((folder) => {
      folder.checked = true;
    });

    setFolders(newCollFolder);
  };

  const uncheckedAllFile = function () {
    let newColl = [...file];

    newColl.find((file) => (file.checked = false));

    setFile(newColl);

    let newCollFolder = [...folder];

    newCollFolder.find((folder) => (folder.checked = false));

    setFolders(newCollFolder);
  };

  //FILTER
  const folderFilter = (collection) =>
    collection.filter(
      (el) =>
        !searchText ||
        (pathUrlManager.formatNameFolder(el.Prefix.slice(0, -1)) || "")
          .toLowerCase().split(" ").join("")
          .includes(searchText.toLowerCase().split(" ").join(""))
    );

  const fileFilter = (collection) => {
    return collection.filter(
      (el) =>
        !searchText ||
        (pathUrlManager.fileNameCleaner(el.Key) || "")
          .toLowerCase().split(" ").join("")
          .includes(searchText.toLowerCase().split(" ").join(""))
    );
  };


  const editName = async () => {
    if(!!renameFolder){
      //console.warn('rinominiamo ',renameInfo,renameValue)
      await copyFolder(params.Bucket, renameInfo ,params.Bucket, location.pathname.split("/").splice(1).slice(1).join("/") + renameValue)
      .then(() => 
      reloadFolder())
    } else {
      //console.warn('rinominiamo ',decodeURI(location.pathname.split("/").splice(1).slice(1).join("/") + renameValue + '.' + pathUrlManager.getFileExtension(renameInfo)))
      copyObjectsS3({
        Bucket: params.Bucket,
        Key: decodeURI(location.pathname.split("/").splice(1).slice(1).join("/") + renameValue + '.' + pathUrlManager.getFileExtension(renameInfo)),
        CopySource: params.Bucket + "/" + renameInfo
      }, renameInfo)
      .then(async () =>  {
        await deleteFileS3(pathUrlManager.generateThumbnailPath(renameInfo), params.Bucket)
        reloadFolder();
      })
    }
  }

  const showEditName = (key, isFolder = false) => {
    setRename(true)
    // setRenameValue(pathUrlManager.removeFileExtension(key))
    setRenameFolder(isFolder)
    setRenameValue("")
    setRenameInfo(key)
  }

  const deleteModal = (itemKey, action) => {
    //console.warn("policy", policy);
    showModal({
      state: true,
      title: `Are you sure you want to delete?`,
      description: `This item ${itemKey} will be deleted immediately. You can't undo this action.`,
      buttonText: "Confirm",
      action: () => {
        hideModal();
        action(itemKey);
      },
      undoButtonText: "Back",
      undoButtonAction: () => {
        hideModal();
      },
    });
  };

  const renameFileModal = () => {
    //console.warn("policy", policy);
    setRename(false)
    showModal({
      state: true,
      title: `Are you sure you want to rename?`,
      description: `This item change permanently his name`,
      buttonText: "Confirm",
      action: () => {
        editName();
        hideModal();
      },
      undoButtonText: "Back",
      undoButtonAction: () => {
        hideModal();
      },
    });
  };

  const createTumbnailModal = (itemKey, action) => {
    //console.warn("policy", policy);
    showModal({
      state: true,
      title: `Are you sure you want to create tumbnail?`,
      description: `We will create a tumbnail for all file`,
      buttonText: "Confirm",
      action: () => {
        hideModal();
        createAllThumbnail();
      },
      undoButtonText: "Back",
      undoButtonAction: () => {
        hideModal();
      },
    });
  };

  const stopPropagation = (e) => {
    e.stopPropagation();
  };

  const renameInput = useCallback((inputElement) => {
    if (inputElement) {
      inputElement.focus();
    }
  }, []);

  if (viewLoader) {
    return <Loader />;
  }
  return (
   <>{!!rename ? 
      <div className="rename-folder" onClick={(e) => setRename(false)}>
        <div className="modal-folder" onClick={(e) => stopPropagation(e)}>
          <div className="input-folder-container">
            <input
              className="input-folder"
              ref={renameInput}
              placeholder="new name..."
              value={renameValue}
              onChange={(e) => setRenameValue(e.target.value)}
              type="text"
            />
          </div>
          <div className="add-folder-container">
            <Button
              variant="contained"
              disabled={!renameValue}
              onClick={() => renameFileModal()}
              className="primary-button blue-active-bg max-width"
            >
              Confirm
            </Button>
          </div>
        </div>
      </div> : (null)
    }
    
    <div className="page">
     
      <Snackbar
        open={toast}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        autoHideDuration={6000}
        onClose={() => setToast(false)}
      >
        <Alert
          onClose={() => setToast(false)}
          severity="error"
          sx={{ width: "100%" }}
        >
          Error
        </Alert>
      </Snackbar>
      <div className="navbar">
        <UserLabel visibleName={true}></UserLabel>
      </div>
      <div className="breadcrumb-container">
        {breadcrumb.map((item, index) => (
          <div className="row" key={index}>
            {index > 0 && item !== "" ? (
              <img src={SlashIcon} className="img-icon-size" alt="slash icon" />
            ) : null}
            {item !== "" ? (
              index > breadcrumb.length - 3 ? (
                <div className="row-block-disabled">
                  <p className="big-text">{decodeURI(item)}</p>
                </div>
              ) : (
                <Link
                  to={`${pathUrlManager.breadcrumbGoTo(location, index)}`}
                  className="row-block"
                >
                  <p className="big-text">{decodeURI(item)}</p>
                </Link>
              )
            ) : null}
          </div>
        ))}
        <div className="row">
          {keycloak.authenticated 
          && !!readOnly ? 
          <>
            <img src={SlashIcon} className="img-icon-size" alt="slash icon" />
            <IconButton
              variant="contained"
              onClick={() => view({ path: location.pathname, newFolder: true })}
              className="square-primary-button blue-active-bg"
            >
              <AddFolderIcon className="img-icon-size" />
            </IconButton> 
          </>
          : (null)}
        </div>
      </div>
      <div className="collection-action-container">
        <div className="searchbar-container">
          {!searchText ? (
            <img
              src={SearchIconBlue}
              className="img-icon-size mr-5"
              alt="search icon"
            />
          ) : (
            <img
              src={DeleteIconBlue}
              className="img-icon-size mr-5 pointer"
              onClick={() => setSearchText("")}
              alt="delete icon"
            />
          )}
          <input
            type="text"
            placeholder="search..."
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            className="input-serchbar big-text"
          />
        </div>
        <div className="actions-container">
        {keycloak.authenticated 
          && !!readOnly ? 
          <IconButton onClick={() => createTumbnailModal()}
            variant="contained"
            className="square-primary-button blue-active-bg"
          >
            <BsCardImage className="img-icon-size color-white" />
          </IconButton> : (null)
          }
          <div className="margin-left" onClick={() => setViewMode(!viewMode)}>
            {viewMode ? (
              <IconButton
                variant="contained"
                className="square-primary-button blue-active-bg"
              >
                <ListIcon className="img-icon-size" />
              </IconButton>
            ) : (
              <IconButton
                variant="contained"
                className="square-primary-button blue-active-bg"
              >
                <GridIcon className="img-icon-size" />
              </IconButton>
            )}
            
          </div>
        </div>
      </div>
      {
        !file.length && !folder.length && <h1>No file or folder in this path</h1>
      }
      {viewMode ? (
        // CARD VIEW
        <div className="cardContainer">
          {folderFilter(folder).map((item, index) => (
            <Folder
              key={index}
              folder={item}
              viewMode={viewMode}
              location={location}
              setCardHover={setCardHover}
              downloadFolder={downloadFolder}
              deleteFolder={deleteFolder}
              editName={showEditName}
              deleteModal={deleteModal}
              checkedFolder={checkedFolder}
              readOnly={readOnly}
            />
          ))}
          {fileFilter(file).map((item, index) =>
            pathUrlManager.fileNameCleaner(item.Key).length > 0 ? (
              <FileTest
                key={index}
                file={item}
                viewMode={viewMode}
                location={location}
                setCardHover={setCardHover}
                downloadFile={downloadFile}
                deleteFile={deleteFile}
                deleteModal={deleteModal}
                returnDate={returnDate}
                editName={showEditName}
                checkedFile={checkedFile}
                readOnly={readOnly}
              />
            ) : null
          )}
        </div>
      ) : (
        // ROW VIEW
        <div className="row-table-container">
          <div className="row-table">
            <div className="table-row table-header">
              <div className="name-header table-header-text big-text">Name</div>
              <div className="size-header table-header-text big-text">Size</div>
              <div className="modify-header table-header-text big-text">
                Last Modified
              </div>
              <div className="download-header table-header-text big-text">
                Action
              </div>
            </div>
            {folderFilter(folder).map((item, index) => (
              <Folder
                key={index}
                folder={item}
                viewMode={viewMode}
                location={location}
                setCardHover={setCardHover}
                downloadFolder={downloadFolder}
                deleteFolder={deleteFolder}
                editName={showEditName}
                deleteModal={deleteModal}
                checkedFolder={checkedFolder}
                readOnly={readOnly}
              />
            ))}
            {fileFilter(file).map((item, index) => (
              <FileTest
                key={index}
                file={item}
                viewMode={viewMode}
                location={location}
                setCardHover={setCardHover}
                downloadFile={downloadFile}
                deleteFile={deleteFile}
                editName={showEditName}
                deleteModal={deleteModal}
                returnDate={returnDate}
                checkedFile={checkedFile}
                readOnly={readOnly}
              />
            ))}
          </div>
        </div>
      )}
      
      {fileStore?.length ? null : (
        <div className="button-area">
          {(!!file.find((el) => !!el.checked) ||
            !!folder.find((el) => !!el.checked)) && (
            <div className="buttons">
              {
              !!keycloak.authenticated &&
              (
                <Button
                  variant="contained"
                  onClick={() => deleteModal("selected items", () => deleteAll())}
                  startIcon={<DeleteIcon className="icon-size-small" />}
                  className={`primary-button danger-bg mr-base ${
                    readOnly ? "" : "read-only"
                  }`}
                >
                  Delete all
                </Button>
              )}
              {(!!file.find((el) => !el.checked) ||
                !!folder.find((el) => !el.checked)) && (
                <Button
                  variant="contained"
                  onClick={() => checkedAllFile()}
                  startIcon={<CheckIconWhite className="icon-size-small" />}
                  className="primary-button blue-active-bg mr-base"
                >
                  Select all
                </Button>
              )}
              {(!file.find((el) => !el.checked) ||
                !folder.find((el) => !el.checked)) && (
                <Button
                  variant="contained"
                  onClick={() => uncheckedAllFile()}
                  startIcon={<NotCheckIcon className="icon-size-small" />}
                  className="primary-button blue-active-bg mr-base"
                >
                  Unselect all
                </Button>
              )}
              <Button
                variant="contained"
                onClick={() => downloadAllFile()}
                startIcon={<DownloadIconWhite className="icon-size-small" />}
                className="primary-button blue-active-bg"
              >
                Download all
              </Button>
            </div>
          )}
          {!file.find((el) => !!el.checked) &&
            !folder.find((el) => !!el.checked) && 
              !!keycloak.authenticated &&
               !!readOnly &&
            (
              <Fab
                className="fab-primary-button blue-active-bg"
                onClick={() => {
                  view({ path: location.pathname });
                  //console.log({ path: location.pathname });
                }}
              >
                <AddIconWhite className="img-icon-size" />
              </Fab>
            )}
        </div>
      )}
    </div>
    </>
  );
};

export default BucketViewer;
