function sum( obj ) {
    var sum = 0;
    for( var el in obj ) {
        if( obj.hasOwnProperty( el ) ) {
        sum += parseFloat( obj[el] );
        }
    }
    return sum;
}


export default sum;