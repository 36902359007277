import { useRecoilState } from "recoil";
import { fileState } from "../recoil/atom/fileState";

export default function useFile() {
  const [fileStore, setFileStore] = useRecoilState(fileState);

  const increment = (fileProps) => {
    setFileStore(fileProps.push({ name: "ciao", nuumber: 0 }));
  };

  const decrement = (state) => {
    const addFile = [{ ...state }];
    setFileStore(
      addFile.splice(
        addFile.findIndex((file) => file.key === state),
        1
      )
    );
    setFileStore(null);
  };

  const update = (state) => {
    // console.log("update", state);
    const addFile = [{ ...state }];
    addFile.find((file) =>
      file.key === state.key
        ? (file.percentCompleted = state.percentCompleted)
        : (file.percentCompleted = 0)
    );

    setFileStore(addFile);
  };

  const incrementByAmount = (state) => {
    // console.log("increment", state);
    setFileStore(state);
  };

  return {
    increment,
    decrement,
    update,
    incrementByAmount,
    fileStore,
  };
}
