import { Outlet } from "react-router-dom";
import FileProgressBar from "../fileProgressBar/FileProgressBar";
import AddFile from "../addFile/addFile";
import Sidebar from "../sidebar/Sidebar";
import './Layout.scss';
import ButtonModal from "../modals/ButtonModal/ButtonModal";

const AppLayout = () => {
    return (
        <div className="layout">
            <Sidebar />
            <FileProgressBar />
            <AddFile />
            <ButtonModal />
            <Outlet />
        </div>
    )
};

export default AppLayout;