import "./ButtonModal.scss";
// import DeleteIconBlue from '../../../assets/icon/delete-selection-icon-blue.svg'
import { Button } from "@mui/material";
import useModal from "../../../hooks/useModal";

const ButtonModal = () => {
  const { hideModal, modal } = useModal();

  const closeModal = () => {
    hideModal();
  };

  const stopPropagation = (e) => {
    e.stopPropagation();
  };

  return (
    modal?.state && (
      <div className="backdrop" onClick={closeModal}>
        <div className="modal" onClick={(e) => stopPropagation(e)}>
          <div className="modal-title-container">
            <p className="big-text">{modal?.title}</p>
            {/* <DeleteIconBlue/> */}
          </div>
          <div className="modal-body-container">
            <p className="text">{modal.description}</p>
          </div>
          <div className="modal-action-container">
            {modal.undoButtonText && (
              <Button
                variant="contained"
                onClick={() => modal.undoButtonAction()}
                className="undo-button primary-button blue-bg max-width"
              >
                {modal.undoButtonText}
              </Button>
            )}
            <Button
              variant="contained"
              onClick={() => modal.action()}
              className="primary-button blue-active-bg max-width"
            >
              {modal.buttonText}
            </Button>
          </div>
        </div>
      </div>
    )
  );
};

export default ButtonModal;
