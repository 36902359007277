import useFile from "../../hooks/useFile";
import "./FileProgressBar.scss";
// import { useSelector, useDispatch } from 'react-redux'

const FileProgressBar = () => {
  // const fileStore = useSelector((state) => state.file.value)
  const { fileStore } = useFile();

  const cutName = (name) => {
    if(name.length > 40){
      return name.slice(0,40) + '...'
    } else {
      return name
    }
  }

  if (fileStore?.length > 0) {
    return (
      <div className="files">
        {fileStore.map((file, index) => (
          <div className="file-container" key={index}>
            <div className="loader">
              <p className="text">
                {`${cutName(file.key)} ${file.percentCompleted}%`}
              </p>
              <div className="bar-container">
                <div className="cover-bar">
                  <div
                    className="load-bar"
                    style={{ width: file.percentCompleted + "%" }}
                  ></div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  } else {
    return;
  }
};

export default FileProgressBar;
