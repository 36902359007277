import { useKeycloak } from "@react-keycloak/web";
import axios from "axios";
import { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { s3ConfigState } from "../recoil/atom/s3ConfigState";
import AWS from "aws-sdk";
import { useLocation, useNavigate } from 'react-router-dom'

export default function useConfigS3() {
  const MINUTE_MS = 5000;
  const { initialized, keycloak } = useKeycloak();
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [asService, setAsService] = useState(false);
  const [firstBucket, setFirstBucket] = useState('');
  const [s3Config, setS3Config] = useRecoilState(s3ConfigState);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    //console.warn('initialized', initialized)
    if (initialized) {
      const interval = setInterval(() => {
        (async function () {

    
            
          if(!keycloak.authenticated){ 
          
            var s3Congif = {
              endpoint: process.env.REACT_APP_MINIO,
              s3ForcePathStyle: true,
              signatureVersion: "v4",
              accessKeyId: process.env.REACT_APP_SERVICE_ACCESS_KEY_ID,
              secretAccessKey: process.env.REACT_APP_SERVICE_SECRET_ACCESS_KEY_ID
            }

            // var s3Congif = {
            //   endpoint: "http://10.13.1.32:9000",
            //   s3ForcePathStyle: true,
            //   signatureVersion: "v4",
            //   accessKeyId: "860RTPf1srKSSawT",
            //   secretAccessKey: "5msAzXApyBDch0pqWLMa6lN6Bi2oTFoh",
            // }

            setS3Config(s3Congif);

            const ReactClient = new AWS.S3(s3Congif);

            await ReactClient.listBuckets((err, data) => {
              if (err) {
                console.error("GetFirstBucket public error", err);
              } else {
                //console.warn("GetFirstBucket public", data);
                setFirstBucket(data.Buckets[0].Name)
              }
            });

            //console.warn("as service")
            
            setAsService(true);
            setLoading(false);
            return () => clearInterval(interval);
          } else {

            const isLoggedIn = keycloak.authenticated;
            //console.log('isLogg', isLoggedIn)
           
            if(isLoggedIn){
              try {
                const response = await axios.post(
                  `${process.env.REACT_APP_MINIO}?Action=AssumeRoleWithWebIdentity&WebIdentityToken=${keycloak.token}&Version=2011-06-15&DurationSeconds=86000`
                );
    
                var parser = new DOMParser();
                var xmlDoc = parser.parseFromString(response.data, "text/xml");
    
                const accessKeyId =
                  xmlDoc.getElementsByTagName("AccessKeyId")[0].childNodes[0]
                    .nodeValue;
                const secretAccessKey = xmlDoc.getElementsByTagName("SecretAccessKey")[0].childNodes[0]
                    .nodeValue;
                const sessionToken =
                  xmlDoc.getElementsByTagName("SessionToken")[0].childNodes[0]
                    .nodeValue;
                // configS3.accessKeyId = accessKeyId;
                // configS3.secretAccessKey = secretAccessKey;
                // configS3.sessionToken = sessionToken;
                //console.warn('configS3',configS3)
                var s3Congif = {
                  endpoint: process.env.REACT_APP_MINIO,
                  s3ForcePathStyle: true,
                  signatureVersion: "v4",
                  accessKeyId: accessKeyId,
                  secretAccessKey: secretAccessKey,
                  sessionToken: sessionToken,
                }
    
                setS3Config(s3Congif);
    
                const ReactClient = new AWS.S3(s3Congif);
    
                await ReactClient.listBuckets((err, data) => {
                  if (err) {
                    console.error("error getFirstBucket", err, err.stack);
                  } else {
                    setFirstBucket(data.Buckets[0].Name)
                  }
                });
                
              } catch (err) {
                setError(err);
              } finally {
                setLoading(false);
              }
            } else {  
              keycloak.login()
            }
            
          }
        })();
      }, MINUTE_MS);

      //return () => clearInterval(interval);
    }
  }, [keycloak, initialized]);

  return { error, loading, firstBucket, asService};
}
