import { useEffect, useState } from "react"
import { useKeycloak } from "@react-keycloak/web";
import { keycloackPolicyState } from "../recoil/atom/keycloackPolicyState";
import { useRecoilState } from "recoil";

export default function useConfigPolicy(){

    const { keycloak } = useKeycloak();
    const [policy, setPolicy] = useRecoilState(keycloackPolicyState);

    const initializePolicy = () => {
        if(keycloak.authenticated){
            let policyCollection = [...JSON.parse(atob(keycloak.token.split('.')[1])).policy]
            let policyState = {}
           

            policyCollection.forEach(element => {
                if(element == "readwrite,consoleAdmin" ){
                    policyState['admin'] = true
                } else if(element.split("-")[0] == 'readwrite'){
                    policyState[element.split(/-(.*)/s)[1]] = true
                } else {
                    policyState[element.split(/-(.*)/s)[1]] = false
                }
            });


            setPolicy(policyState)
        } else {
            setPolicy([])
        }
        
    }
    
    
    return { initializePolicy }

}