import { Button } from "@mui/material";
import axios from "axios";
import Lottie from "lottie-react";
import { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useSetRecoilState } from "recoil";
import DeleteIcon, {
  ReactComponent as DeleteIconComponent,
} from "../../assets/icon/delete-selection-icon-blue.svg";
import { ReactComponent as DownloadIconWhite } from "../../assets/icon/download-icon-white.svg";
import useAddFile from "../../hooks/useAddFile";
import useFile from "../../hooks/useFile";
import useS3 from "../../hooks/useS3";
import { reloadState } from "../../recoil/atom/reloadState";
import sum from "../../utils/generic";
import pathUrlManager from "../../utils/pathUrlManager";
import FilePreviewGenerator from "../filePreviewGenerator/filePreviewGenerator";
import Drag from "./../../assets/animations/drag_animazione.json";
import "./addFile.scss";
import { AiFillCloseCircle } from "react-icons/ai";
import { AiOutlineCloudUpload } from "react-icons/ai";

const AddFile = () => {
  const [items, setItems] = useState([]);
  const [fileCollection, setFileCollection] = useState([]);
  const [folderCollection, setFolderCollection] = useState([]);
  const setReload = useSetRecoilState(reloadState);
  const [namingFolderView, setNamingFolderView] = useState(true);
  const [nameFolder, setNameFolder] = useState("");
  const [onChargeFile, setOnChargeFile] = useState(0);
  const [onUploadFile, setOnUploadFile] = useState(false);
  const { fileStore, notView } = useAddFile();
  const { incrementByAmount, update, decrement } = useFile();
  const { getUrlFileS3ToPut } = useS3();

  const onDrop = useCallback(async (acceptedFiles) => {
    setOnChargeFile((prev) => prev + acceptedFiles.length);

    acceptedFiles.map((file, index) => {

        setItems((prevState) => [...prevState, file]);

        let reader = new FileReader();
        //IS FOLDER
        if (file.path.split("/").length > 1) {
          let pathSplit = file.path.split("/");
          var folderName = pathSplit[0] + pathSplit[1];
  
          let folder = file;
  
          folder.folderName = folderName;
  
          setFolderCollection((prevState) => [...prevState, folder]);
  
          //IS FILE
        } else {
          setFileCollection((prevState) => [...prevState, file]);
        }
  
        reader.onprogress = (e) => {
          //console.warn('onprogress', e )
        };
  
        reader.onload = (e) => {
          setOnChargeFile((current) => current - 1);
        };
  
        return reader.readAsDataURL(file);
      
      })
  }, []);




  const rejectFile = (file) => {
    // if (String(file.name).charAt(0) === "_") {
    //   return {
    //     code: "reject-file",
    //     message: `file is not allowed`,
    //   };
    // }

    return null;
  };

  const { getRootProps, getInputProps, isDragActive, fileRejections } =
    useDropzone({
      onDrop,
      fileStore,
      validator: rejectFile,
    });

  const fileRejectionItems = fileRejections.map(({ file, errors }) => (
    <li key={file.path}>
      {file.path} - {file.size} bytes
      <ul>
        {errors.map((e) => (
          <li key={e.code}>{e.message}</li>
        ))}
      </ul>
    </li>
  ));

  const stopPropagation = (e) => {
    e.stopPropagation();
  };

  const closeModal = (isReload = false) => {
    setItems([]);
    setFolderCollection([]);
    setFileCollection([]);
    setNamingFolderView(true);
    setNameFolder("");
    setOnUploadFile(false);
    notView();
    if (isReload) setReload(true);
  };

  const addFile = async (file, nameFile, typeFile, bucket) => {
    let allSize = 0;
    let currentSize = 0;
    let currentTotal = 0;
    let downloads = {};
    let nowKey = `upload_${Math.floor(Date.now() / 1000)}`;

    items.forEach((el, i) => {
      allSize += el.size;
      downloads["file" + i] = 0;
    });

    incrementByAmount({ url: "url", percentCompleted: 0, key: nowKey });

    items.forEach(async (el, i) => {
      let params;


      if (fileStore.newFolder) {
        params = {
          Bucket: pathUrlManager.getBucketByUrl(fileStore.path),
          Key: nameFolder + "/" + pathUrlManager.clearFolder(el.path),
          Expires: 60,
          ContentType: el.type,
        };
      } else {
        params = {
          Bucket: pathUrlManager.getBucketByUrl(fileStore.path),
          Key: pathUrlManager.clearFolder(el.path),
          Expires: 60,
          ContentType: el.type,
        };
      }

      console.warn('questo è el', el)
      
      


      getUrlFileS3ToPut(params)
        .then((res) => {
          var axiosOptions = {
            headers: {
              "Content-Type": el.type,
            },
            onUploadProgress: (progressEvent) => {
              downloads["file" + i] = +progressEvent.loaded;
              currentTotal = sum(downloads);
              let percentCompleted = Math.floor((currentTotal / allSize) * 100);

              update({ key: nowKey, percentCompleted: percentCompleted });
            },
          };

          axios
            .put(res, el, axiosOptions)
            .then(() => {
              currentSize += el.size;
              if (currentSize === allSize) {
                decrement(nowKey);
                closeModal(true);
              }
            })
            .catch((err) => {
              decrement(nowKey);
            });
        })
        .catch((err) => {
          //console.log("error getSignedUrl", err);
        });
    });
  };


  const uploadFile = (e) => {
    setReload(false);
    setOnUploadFile(true);
    e.stopPropagation();
    addFile();
  };

  const deleteFile = (e, name) => {
    e.stopPropagation();
    setFileCollection((current) =>
      current.filter((image) => {
        // 👇️ remove object that has id equal to 2
        return image.name !== name;
      })
    );
    setItems((current) =>
      current.filter((image) => {
        // 👇️ remove object that has id equal to 2
        return image.name !== name;
      })
    );
  };

  const deleteFolder = (e, name) => {
    e.stopPropagation();
    setFolderCollection((current) =>
      current.filter((image) => {
        // 👇️ remove object that has id equal to 2
        return image.folderName !== name;
      })
    );
    setItems((current) =>
      current.filter((image) => {
        // 👇️ remove object that has id equal to 2
        return image.folderName !== name;
      })
    );
  };

  const confirmName = (e = null) => {
    if (e) {
      if (nameFolder === "") {
        return;
      }
      if (e.keyCode === 13) {
        setNamingFolderView(false);
      }
    } else {
      if (nameFolder === "") {
        return;
      } else {
        setNamingFolderView(false);
      }
    }
  };

  const formatFolder = (collection) => {
    return collection.reduce((a, b) => {
      a = a || [];
      if (!a.find((el) => el.folderName === b.folderName)) {
        a.push(b);
      }
      return a;
    }, []);
  };

  const emailInput = useCallback((inputElement) => {
    if (inputElement) {
      inputElement.focus();
    }
  }, []);

  //----- JSX -----//
  return (
    fileStore?.state &&
    !onUploadFile &&
    (fileStore.newFolder && namingFolderView ? (
      <div className="backdrop" onClick={() => closeModal()}>
        <div className="modal-folder" onClick={(e) => stopPropagation(e)}>
          <div className="input-folder-container">
            <input
              className="input-folder"
              ref={emailInput}
              onKeyDown={(e) => confirmName(e)}
              placeholder="name of folder..."
              value={nameFolder}
              onChange={(e) => setNameFolder(e.target.value)}
              type="text"
            />
          </div>
          <div className="add-folder-container">
            <Button
              variant="contained"
              disabled={!nameFolder}
              onClick={() => confirmName()}
              className="primary-button blue-active-bg max-width"
            >
              Confirm
            </Button>
          </div>
        </div>
      </div>
    ) : (
      <div className="backdrop">
        {fileStore.newFolder && (
          <div className="folder-name" onClick={(e) => stopPropagation(e)}>
            <p className="big-text">New folder name : {nameFolder}</p>
          </div>
        )}

        {onChargeFile === 0 ? (
          <div
            className="modal-special"
            style={{
              borderTopLeftRadius: fileStore.newFolder ? `0px` : `10px`,
              borderTopRightRadius: fileStore.newFolder ? `0px` : `10px`,
            }}
            onClick={(e) => stopPropagation(e)}
          >
            <div {...getRootProps()} className="drop">
              <input {...getInputProps()} />
              {/* <FaUpload></FaUpload>
                        <p className='big-text'>Drag 'n' drop some files here, or click to select files</p>
                        <p className='big-text'>and add to  {fileStore.path} folder</p> */}
              <Lottie
                style={{ width: "35%" }}
                animationData={Drag}
                loop={true}
              />
            </div>
          </div>
        ) : (
          <div className="modal">{onChargeFile}...</div>
        )}

        <div className="file-carousel" onClick={(e) => stopPropagation(e)}>
          {formatFolder(folderCollection).map((el, index) => (
            <div key={index} className="mini-file">
              <div className="delete-icon">
                <img
                  alt=""
                  src={DeleteIcon}
                  onClick={(e) => deleteFolder(e, el.folderName)}
                  className="icon-size-small"
                />
              </div>
              <div className="mini-file-preview">
                <FilePreviewGenerator noClass={true} folder={true} />
              </div>
              <div className="mini-file-name">{el.folderName}</div>
            </div>
          ))}
          {fileCollection.map((el, index) => (
            <div key={index} className="mini-file">
              <div className="delete-icon">
                <img
                  alt=""
                  src={DeleteIcon}
                  onClick={(e) => deleteFile(e, el.name)}
                  className="icon-size-small"
                />
              </div>
              <div className="mini-file-preview">
                <FilePreviewGenerator noClass={true} fileType={el.name} />
              </div>

              {el.name.length > 20 ? (
                <div className="mini-file-name">
                  {el.name.slice(0, 10) +
                    "..." +
                    el.name.slice(el.name.length - 10, el.name.length)}
                </div>
              ) : (
                <div className="mini-file-name">{el.name}</div>
              )}
            </div>
          ))}
        </div>
        {/* <ul>{fileRejectionItems}</ul> */}
        <div className="folder-bottom" onClick={(e) => stopPropagation(e)}>
          {fileStore.newFolder ? (
            <Button
              variant="contained"
              onClick={() => setNamingFolderView(true)}
              startIcon={<AiFillCloseCircle className="icon-size-small" />}
              className="undo-button primary-button blue-bg"
            >
              Back
            </Button>
          ) : (
            <Button
              variant="contained"
              onClick={() => closeModal()}
              startIcon={<AiFillCloseCircle className="icon-size-small" />}
              className="undo-button primary-button blue-bg"
            >
              Close
            </Button>
          )}
          <Button
            variant="contained"
            startIcon={!fileCollection.length && !folderCollection.length ? <AiOutlineCloudUpload className="icon-size-small" /> : <AiOutlineCloudUpload className="icon-size-small" />}
            disabled={!fileCollection.length && !folderCollection.length}
            onClick={(e) => uploadFile(e)}
            className="primary-button blue-active-bg"
          >
            Upload
          </Button>
        </div>
      </div>
    ))
  );
};

export default AddFile;
