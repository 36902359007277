import JsZip from 'jszip';
import FileSaver from 'file-saver';
import pathUrlManager from './pathUrlManager';

const exportZip = (blobs, nameZip) => {
    return new Promise( async (res, rej) => {
        const zip = JsZip();
        for (const blob of blobs) {
            zip.file(blob.nameFile, blob.blob, {base64: true})
        }
        await zip.generateAsync({type: 'blob'})
        .then(async (zipFile) => {
            const fileName = nameZip
            await FileSaver.saveAs(zipFile, fileName)
            res(true)
        })
        .catch((err) => rej(err))
    })
}

const browserDownloadFile = (nameFile, data) => {

    let formatName = pathUrlManager.fileNameCleaner(nameFile);

    const blobUrl = URL.createObjectURL(new Blob([data.data]));
    // Create a link element
    const link = document.createElement('a');
    // Set link's href to point to the Blob URL
    link.href = blobUrl;
    link.setAttribute('download', data.headers.filename);
    link.download = formatName;
    // Append link to the body
    document.body.appendChild(link);
    // Dispatch click event on the link
    // This is necessary as link.click() does not work on the latest firefox
    link.dispatchEvent(
        new MouseEvent('click', {
        //bubbles: true,
        //cancelable: true,
        //view: window,
        })
    );    
}

const getFileImg = function(file){
    switch(pathUrlManager.getFileExtension(file)){
        case 'xlsx':
            return './../assets/file-type/XLSX-icon.svg' 
        case 'svg':
            return 'https://cdn-icons-png.flaticon.com/512/29/29495.png'
        case 'pdf':
            return 'https://upload.wikimedia.org/wikipedia/commons/thumb/3/38/Icon_pdf_file.svg/1795px-Icon_pdf_file.svg.png'
        case 'mp4':
            return 'https://w7.pngwing.com/pngs/906/416/png-transparent-computer-icons-mpeg-4-part-14-mp4-icon-angle-text-logo-thumbnail.png'
        case 'csv':
            return 'https://cdn-icons-png.flaticon.com/512/202/202302.png'
        case 'png':
            return 'https://cdn-icons-png.flaticon.com/512/337/337948.png'
        case 'jpg':
            return 'https://learn.getgrav.org/system/images/media/thumb-jpg.png'
        default:
            return 'https://icons-for-free.com/download-icon-file+icon-1320087274221188067_512.png'
    }
}




const FileManager = {
    exportZip,
    browserDownloadFile,
    getFileImg
};
  
export default FileManager;
