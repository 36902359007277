import { useRecoilState } from "recoil";
import { modalState } from "../recoil/atom/modalState";

export default function useModal() {
  const [modal, setModal] = useRecoilState(modalState);

  const showModal = (modalProps) => {
    setModal(modalProps);
  };

  const hideModal = () => {
    setModal(null);
  };

  return {
    modal,
    setModal,
    showModal,
    hideModal,
  };
}
