import { useRecoilState } from "recoil";
import { addFileState } from "../recoil/atom/addFileState";

export default function useAddFile() {
  const [fileStore, setFileStore] = useRecoilState(addFileState);

  const view = ({ path, newFolder }) => {
    setFileStore({
      state: true,
      path,
      newFolder,
    });
  };

  const notView = () => {
    setFileStore(null);
  };

  return {
    fileStore,
    setFileStore,
    view,
    notView,
  };
}
