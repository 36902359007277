import { useKeycloak } from "@react-keycloak/web";
import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import "./App.scss";
import { PrivateRoute } from "./auth";
import AppLayout from "./components/layout/AppLayout";
import Loader from "./components/loader/Loader";
import useConfigS3 from "./hooks/useConfigS3";
import keycloak from "./keycloak";
import BucketViewer from "./pages/BucketViewer/BucketViewer";


function App() {
  const { initialized } = useKeycloak();
  const { loading, error, firstBucket, asService} = useConfigS3();
 
  


  if (initialized && !loading && firstBucket && !asService) {
    return (
      
        <Routes>
          {/* DEFAULT LAYOUT */}
         
            <Route path="/" element={<AppLayout />}>
              
              {/* DEFAULT ROUTE */}
              <Route 
                index
                element={
                  <PrivateRoute>
                    <Navigate to={"/" + firstBucket} />
                  </PrivateRoute>
                }
              ></Route>
              {/* <Route index element={<LoginPage/>} /> */}
              {/* FIRST LEVEL OF BUCKET */}
              <Route
                path="/:id"
                element={
                  <PrivateRoute>
                    <BucketViewer />
                  </PrivateRoute>
                }
              ></Route>
              {/* SECOND AND MORE LEVEL OF FOLDER IN BUCKET */}
              <Route
                path="/:id/:prefix/*"
                element={
                  <PrivateRoute>
                    <BucketViewer />
                  </PrivateRoute>
                }
              ></Route>
            </Route>
         
        </Routes>
    );
  } else if(initialized && !loading && asService) {
    return (
      <Routes>
        <Route path="/" element={<AppLayout />}>
        <Route 
                index
                element={
                  <Navigate to={"/" + firstBucket} />
                }
              ></Route>
              {/* <Route index element={<LoginPage/>} /> */}
              {/* FIRST LEVEL OF BUCKET */}
              <Route
                path="/:id"
                element={
                  <BucketViewer />
                }
              ></Route>
              {/* SECOND AND MORE LEVEL OF FOLDER IN BUCKET */}
              <Route
                path="/:id/:prefix/*"
                element={
                  <BucketViewer />
                }
              ></Route>
        </Route>
      </Routes>
    );
  } else {
    return (
      <Loader />
    );
  }
}

export default App;
