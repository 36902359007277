import AiIcon from "./../../assets/file-type/AI-icon.svg";
import CsvIcon from "./../../assets/file-type/CSV-icon.svg";
import DocxIcon from "./../../assets/file-type/DOCX-icon.svg";
import EpsIcon from "./../../assets/file-type/EPS-icon.svg";
import EmptyIcon from "./../../assets/file-type/extension-empty-icon.svg";
import FolderIcon from "./../../assets/file-type/folder-icon.svg";
import JpgIcon from "./../../assets/file-type/JPG-icon.svg";
import Mp4Icon from "./../../assets/file-type/MP4-icon.svg";
import PdfIcon from "./../../assets/file-type/PDF-icon.svg";
import PngIcon from "./../../assets/file-type/PNG-icon.svg";
import PptxIcon from "./../../assets/file-type/PPTX-icon.svg";
import SvgIcon from "./../../assets/file-type/SVG-icon.svg";
import XlsxIcon from "./../../assets/file-type/XLSX-icon.svg";
import "./FilePreviewGenerator.scss";

import { useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import pathUrlManager from "../../utils/pathUrlManager";
import { ImageModal } from "../modals/ImageModal/ImageModal";

const FilePreviewGenerator = (props) => {
  const getPropsUrl = () => {
   
    return props.url;
  };

  const [handleModal, setHandleModal] = useState(false);

  if (props.folder) {
    return (
      <img
        alt=""
        className={props.noClass ? "img-preview-standard" : "img-preview"}
        src={FolderIcon}
      />
    );
  } else {
    switch (pathUrlManager.getFileExtension(props.fileType)) {
      case "xlsx":
        return (
          <img
            alt=""
            className={props.noClass ? "img-preview-standard" : "img-preview"}
            src={XlsxIcon}
          />
        );
      case "svg":
        return (
          <img
            alt=""
            className={props.noClass ? "img-preview-standard" : "img-preview"}
            src={SvgIcon}
          />
        );
      case "pdf":
        return (
          <img
            alt=""
            className={props.noClass ? "img-preview-standard" : "img-preview"}
            src={PdfIcon}
          />
        );
      case "mp4":
        if (props?.preview) {
          if (props?.url) {
            return (
              <video controls width="250" className="video-preview">
                <source src={getPropsUrl()} type="video/mp4" />
              </video>
            );
          }
        } else {
          return (
            <img
              alt=""
              className={props.noClass ? "img-preview-standard" : "img-preview"}
              src={Mp4Icon}
            />
          );
        }

      case "csv":
        return (
          <img
            alt=""
            className={props.noClass ? "img-preview-standard" : "img-preview"}
            src={CsvIcon}
          />
        );
      case "pptx":
        return (
          <img
            alt=""
            className={props.noClass ? "img-preview-standard" : "img-preview"}
            src={PptxIcon}
          />
        );
      case "ai":
        return (
          <img
            alt=""
            className={props.noClass ? "img-preview-standard" : "img-preview"}
            src={AiIcon}
          />
        );
      case "docx":
        return (
          <img
            alt=""
            className={props.noClass ? "img-preview-standard" : "img-preview"}
            src={DocxIcon}
          />
        );
        case "eps":
          return (
            <img
              alt=""
              className={props.noClass ? "img-preview-standard" : "img-preview"}
              src={EpsIcon}
            />
          );
        case "tiff":
        if (props.preview) {
        
          return (
            <>
              <LazyLoadImage
              effect="blur"
                className={
                  props.noClass ? "img-preview-standard" : "img-preview"
                }
                refs="image"
                src={getPropsUrl()}
                type="image/tiff"
                alt="IE/CHROME/FIREFOX not support breview, only Safari"
                onClick={() => setHandleModal(true)}
              />
              <ImageModal
                open={handleModal}
                src={getPropsUrl()}
                setOpen={setHandleModal}
              />
            </>
          );
        } else {
          return (
            <img
              alt=""
              className={props.noClass ? "img-preview-standard" : "img-preview"}
              src={JpgIcon}
            />
          );
        }
        case "tif":
        if (props.preview) {
        
          return (
            <>
              <LazyLoadImage
              effect="blur"
                className={
                  props.noClass ? "img-preview-standard" : "img-preview"
                }
                refs="image"
                src={getPropsUrl()}
                type="image/tif"
                alt="IE/CHROME/FIREFOX not support breview, only Safari"
                onClick={() => setHandleModal(true)}
              />
              <ImageModal
                open={handleModal}
                src={getPropsUrl()}
                setOpen={setHandleModal}
              />
            </>
          );
        } else {
          return (
            <img
              alt=""
              className={props.noClass ? "img-preview-standard" : "img-preview"}
              src={JpgIcon}
            />
          );
        }
        case "jpg":
          if (props.preview) {
            return (
              <>
                <LazyLoadImage
                effect="blur"
                  className={
                    props.noClass ? "img-preview-standard" : "img-preview"
                  }
                  refs="image"
                  src={getPropsUrl()}
                
                  onClick={() => setHandleModal(true)}
                />
                <ImageModal
                  open={handleModal}
                  src={getPropsUrl()}
                  setOpen={setHandleModal}
                />
              </>
            );
          } else {
            return (
              <img
                alt=""
                className={props.noClass ? "img-preview-standard" : "img-preview"}
                src={JpgIcon}
              />
            );
          }
      case "jpeg":
        if (props.preview) {
          return (
            <LazyLoadImage
            effect="blur"
              className={props.noClass ? "img-preview-standard" : "img-preview"}
              refs="image"
              src={getPropsUrl()}
           
            />
          );
        } else {
          return (
            <img
              alt=""
              className={props.noClass ? "img-preview-standard" : "img-preview"}
              src={JpgIcon}
            />
          );
        }
      case "png":
        if (props.preview) {
          return (
            <LazyLoadImage
              className={props.noClass ? "img-preview-standard" : "img-preview"}
              effect="blur"
              refs="image"
              src={getPropsUrl()}
            />
          );
        } else {
          return (
            <img
              alt=""
              className={props.noClass ? "img-preview-standard" : "img-preview"}
              src={PngIcon}
            />
          );
        }
      default:
        return (
          <img
            alt=""
            className={props.noClass ? "img-preview-standard" : "img-preview"}
            src={EmptyIcon}
          />
        );
    }
  }
};

export default FilePreviewGenerator;
