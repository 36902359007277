import './Loader.scss';
import Lottie from "lottie-react";
import groovyWalkAnimation from './../../assets/animations/loader.json';

const Loader = () => {
    


    
    return <div className='backdrop'>
        <Lottie className='lottie' animationData={groovyWalkAnimation} loop={true} />
    </div>
};

export default Loader;