/* eslint-disable no-loop-func */
import AWS from "aws-sdk";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { bucketState } from "../recoil/atom/bucketState";
//import configS3 from "../aws-s3";
import { s3ConfigState } from "../recoil/atom/s3ConfigState";
import FileManager from "../utils/fileManager";
import sum from "../utils/generic";
import useFile from "./useFile";

export default function useS3() {
  const { decrement, incrementByAmount, update } = useFile();
  const configS3 = useRecoilValue(s3ConfigState);
  const navigate = useNavigate();

  const ReactClient = new AWS.S3(configS3);
  const setBucketState = useSetRecoilState(bucketState);

  const getObjectsS3 = (params) =>
    new Promise(async (resolve, reject) => {
      ReactClient.listObjects(params, (err, data) => {
        if (err) {
          navigate('/');
          window.location.reload();
          
          reject(err);
        } else {
          resolve(data);
        }
      });
    });


  const copyObjectsS3 = (params, key) => new Promise(async (resolve, reject) => {
    await ReactClient.copyObject(params, async (err, data) => {
      if (err) { 
        reject(err);
      } else {
        await deleteFileS3(key, params.Bucket)
        .then(() => resolve(data))
        .catch(() => resolve(data))
      }
    });
  });

 const copyFolder = async (fromBucket, fromFolderKey, toBucket, toFolderKey) => new Promise(async (resolve, reject) => {
    await ReactClient.listObjects({
      Bucket: fromBucket,
      Prefix: `${fromFolderKey}`
    })
    .promise().then(async (res) => {
      console.warn(fromBucket, fromFolderKey, toBucket, toFolderKey)
      const fromObjectKeys = await res.Contents.map(content => content.Key)
      console.warn("after map")
      let number = 0
      for (let index = 0; index < fromObjectKeys.length; index++) {
        let fromObjectKey = fromObjectKeys[index];
        const toObjectKey = await fromObjectKey.replace(fromFolderKey, toFolderKey + "/");
        console.warn("after replace")
        await ReactClient.copyObject({
          Bucket: toBucket,
          CopySource: `${fromBucket}/${fromObjectKey}`,
          Key: toObjectKey,
        }, async (err, data) => {
          if (err) { 
            //console.warn("errore", err)
           
          } else {
            number++
            console.warn("fatto")
            if(number == fromObjectKeys.length){
              resolve()
            }
          }
        });
      }

    
      
    })
  })

  const getUrlFileS3 = (bucket, key) =>
    new Promise(async (resolve, reject) => {
      let options = {
        Bucket: bucket,
        Key: key,
      };

      ReactClient.getSignedUrl("getObject", options, async function (err, url) {
        if (err) {
          //console.log(err, err.stack);
        } else {
          resolve(url);
        }
      });

      
    });

    const getHeadFileS3 = (bucket, key) => new Promise(async (resolve, reject) => {
      ReactClient.headObject({
        Bucket: bucket,
        Key: key,
      }).promise()
      .then((res) => {
        resolve(true)
      })
      .catch((err) => {
        resolve(false)
      })
    });

  const getFileInFolderS3 = (bucket, folder) =>
    new Promise(async (resolve, reject) => {
      const params = {
        Bucket: bucket,
        Prefix: folder.Prefix,
      };

      return ReactClient.listObjectVersions(params, async function (err, url) {
        if (err) {
          //console.log(err, err.stack);
          reject(err);
        } else {
          resolve(url);
        }
      });
    });

  const downloadFolderS3 = async (item, bucket) =>
    new Promise(async (resolve, reject) => {
      getFileInFolderS3(bucket, item)
        .then(async (res) => {
          const listedObjects = res;
          let allSize = 0;
          let currentTotal = 0;
          let downloads = {};
          let nowKey = `mfDocs_${Math.floor(Date.now() / 1000)}`;
          let blobCollection = [];

          listedObjects.Versions.forEach((el, i) => {
            allSize += el.Size;
            downloads["file" + i] = 0;
          });

          incrementByAmount({ url: "url", percentCompleted: 0, key: nowKey });

          await listedObjects.Versions.forEach(async (el, i) => {
            getUrlFileS3(bucket, el.Key)
              .then(async (url) => {
                const client = axios.create({ baseURL: res });
                await client
                  .get(url, {
                    responseType: "arraybuffer",
                    onDownloadProgress: (progressEvent) => {
                      downloads["file" + i] = +progressEvent.loaded;
                      currentTotal = sum(downloads);
                      let percentCompleted = Math.floor(
                        (currentTotal / allSize) * 100
                      );
                      update({
                        key: nowKey,
                        percentCompleted: percentCompleted,
                      });
                    },
                  })
                  .then((res) => {
                    let file = {
                      blob: new Blob([res.data], {
                        type: res.headers["content-type"],
                      }),
                      nameFile: el.Key,
                    };

                    blobCollection.push(file);

                    if (currentTotal === allSize) {
                      decrement(nowKey);
                      FileManager.exportZip(blobCollection, nowKey);
                    }

                    //downloadBlob('zip', res)
                    resolve(res.data);
                  })
                  .catch((err) => {
                    decrement(nowKey);
                    reject("erron in axios on downloadFolder", err);
                  });
              })
              .catch((err) =>
                reject("erron in getUrlFileS3 on downloadFolder", err)
              );
          });
        })
        .catch((err) =>
          reject("erron in getFileInFolderS3 on downloadFolder", err)
        );
    });

  const deleteFileS3 = (nameFile, BucketId) => new Promise(async (resolve, reject) => {
      const params = {
        Bucket: BucketId,
        Key: nameFile,
      };

      console.log("delete obj", params);

      await ReactClient.deleteObject(params, function (err, data) {
        if (err) {
          //console.log("delete obj", err, err.stack);
          reject("delete obj", err, err.stack);
        } else {
          resolve(data);
        }
      });
    });

  const deleteFolderS3 = (folder, BucketId) => new Promise(async (resolve, reject) => {
    const params = {
      Bucket: BucketId,
      Prefix: folder.Prefix,
    };

    const listedObjects = await ReactClient.listObjectVersions(
      params
    ).promise();

    listedObjects.Versions.forEach((el) => {
      const params = {
        Bucket: BucketId,
        Key: el.Key,
      };

      ReactClient.deleteObject(params, function (err, data) {
        if (err) {
          //console.log("delete folder", err, err.stack);
          reject("delete folder", err, err.stack);
        } else {
          resolve("delete folder", data);
        }
      });
    });
  });

  const deleteTumbnailFile = async (nameFile, bucket) => {
    deleteFileS3('mf-tumbnail/' + nameFile, bucket)
  };

  const deleteAllS3 = (folder, file, bucket) =>
    new Promise(async (resolve, reject) => {
      let folderToDeleteCollection = folder.filter((el) => el.checked === true);
      let fileToDeleteCollection = file.filter((el) => el.checked === true);

      let fileInFolderToDeleteCollection = [];

      for (const folderToDelete of folderToDeleteCollection) {
        await getFileInFolderS3(bucket, folderToDelete)
          .then((res) => {
            for (const file of res.Versions) {
              fileInFolderToDeleteCollection.push(file);
            }
          })
          .catch((err) => {
            //console.error("getFileInFolderS3", err);
            reject(err);
          });
      }

      let fileAndFolderToDeleteCollection = [
        ...fileToDeleteCollection,
        ...fileInFolderToDeleteCollection,
      ];

      for (const file of fileAndFolderToDeleteCollection) {
        await deleteFileS3(file.Key, bucket)
          .then((res) => {
            deleteTumbnailFile(file.Key, bucket)
            //console.warn("deleteFileS3 good");
          })
          .catch((err) => {
            //console.error("deleteFileS3", err);
            reject(err);
          });
      }

      resolve();
    });

  const downloadFileS3 = (bucket, key) =>
    new Promise(async (resolve, reject) => {
      await getUrlFileS3(bucket, key)
      .then(async (res) => {
        const client = axios.create({ baseURL: res });
        incrementByAmount({ url: res, percentCompleted: 0, key: key });
        await client
          .get(res, {
            responseType: "arraybuffer",
            onDownloadProgress: (progressEvent) => {
              const total = progressEvent.total;
              const current = progressEvent.loaded;
              let percentCompleted = Math.floor((current / total) * 100);
              update({ key: key, percentCompleted: percentCompleted });
            },
          })
          .then((res) => {
            //console.log("All DONE: ", res);
            decrement(key);
            FileManager.browserDownloadFile(key, res);
            resolve(res.data);
          })
          .catch((err) => reject("axios client.get", err));
      })
      .catch((err) => {
        reject("getUrlFileS3", err);
      });
  });

  const getFileS3 = (bucket, key) =>
    new Promise(async (resolve, reject) => {
      await getUrlFileS3(bucket, key)
      .then(async (res) => {
        const client = axios.create({ baseURL: res });
        incrementByAmount({ url: res, percentCompleted: 0, key: key });
        await client
          .get(res, {
            responseType: "arraybuffer",
            onDownloadProgress: (progressEvent) => {
              const total = progressEvent.total;
              const current = progressEvent.loaded;
              let percentCompleted = Math.floor((current / total) * 100);
              update({ key: key, percentCompleted: percentCompleted });
            },
          })
          .then((res) => {
            //console.log("All DONE: ", res);
            decrement(key);
            resolve(res.data);
          })
          .catch((err) => reject("axios client.get", err));
      })
      .catch((err) => {
        reject("getUrlFileS3", err);
      });
  });

  const downloadAllFileS3 = (bucket, folder, file) =>
    new Promise(async (resolve, reject) => {
      let folderToDownload = folder.filter((el) => el.checked === true);
      let fileInFolderToDownload = [];

      for (const folder of folderToDownload) {
        await getFileInFolderS3(bucket, folder)
          .then((res) => {
            res.Versions.forEach((file) => {
              fileInFolderToDownload.push(file);
            });
          })
          .catch((err) => {
            reject("Error in downloadAllFile() on getFileInFolderS3()", err);
          });
      }

      let fileToDownload = file.filter((el) => el.checked === true);

      fileToDownload = [...fileToDownload, ...fileInFolderToDownload];

      let allSize = 0;
      let currentTotal = 0;
      let downloads = {};
      let nowKey = `mfDocs_${Math.floor(Date.now() / 1000)}`;
      let blobCollection = [];

      fileToDownload.forEach((el, i) => {
        allSize += el.Size;
        downloads["file" + i] = 0;
      });

      incrementByAmount({ url: "url", percentCompleted: 0, key: nowKey });

      for (let i = 0; i < fileToDownload.length; i++) {
        const file = fileToDownload[i];
        await getUrlFileS3(bucket, file.Key)
          .then(async (url) => {
            //console.warn("downloadAllFile", url);
            const client = axios.create({ baseURL: url });
            await client
              .get(url, {
                responseType: "arraybuffer",
                onDownloadProgress: (progressEvent) => {
                  downloads["file" + i] = +progressEvent.loaded;

                  currentTotal = sum(downloads);

                  let percentCompleted = Math.floor(
                    (currentTotal / allSize) * 100
                  );

                  update({ key: nowKey, percentCompleted: percentCompleted });
                },
              })
              .then(async (res) => {
                //console.log("All DONE: ", res);

                let fileToZip = {
                  blob: new Blob([res.data], {
                    type: res.headers["content-type"],
                  }),
                  nameFile: file.Key,
                };

                blobCollection.push(fileToZip);

                if (currentTotal === allSize) {
                  FileManager.exportZip(blobCollection, nowKey)
                    .then(() => decrement(nowKey))
                    .catch((err) => {
                      decrement(nowKey);
                      reject("erron in exportZip on downloadAll", err);
                    });
                }

                return res.data;
              })
              .catch((err) => {
                //warn("error axios", err);
                decrement(nowKey);
                reject("erron in Axios on downloadAll", err);
              });
          })
          .catch((err) => reject("erron in getUrlFileS3 on downloadAll", err));
      }
    });

  const getBucketS3 = () =>
    new Promise(async (resolve, reject) => {
      await ReactClient.listBuckets((err, data) => {
        if (err) {
          //console.error("error getBucketS3 ", err, err.stack);
          reject(err);
        } else {
        //   console.warn("getBucketS3 data", data);
          setBucketState(data.Buckets);
          resolve(data.Buckets);
        }
      });
    });

  const getUrlFileS3ToPut = (options) =>
    new Promise(async (resolve, reject) => {
      ReactClient.getSignedUrl("putObject", options, async function (err, url) {
        if (err) {
          console.log(err, err.stack);
          reject(err);
        } else {
          resolve(url);
        }
      });
    });

  return {
    deleteFileS3,
    deleteFolderS3,
    deleteAllS3,
    getUrlFileS3,
    downloadFolderS3,
    downloadFileS3,
    getFileS3,
    getObjectsS3,
    downloadAllFileS3,
    getBucketS3,
    getUrlFileS3ToPut,
    copyObjectsS3,
    copyFolder,
    getHeadFileS3
  };
}
