const fileNameCleaner = (name) => {
    let pathname = name.split("/");
    let lastname = pathname.at(-1)
    return lastname
}

const clearFolder = (string) => {
    if(string.charAt(0) === '/')
    {
     return string = string.substring(1);
    }
    else {
        return string
    }
}

const prefixGenerator = (location) => {
    let pathname = location.pathname.split("/");

    let cleanPath = pathname.splice(2)

    let reunion = cleanPath.join('/')

    let decodeReunion = decodeURI(reunion)

    return decodeReunion
}

const prefixGoTo = (folder, location) => {

    let pathname = location.pathname.split("/");
  
    let cleanPath = pathname.splice(0,2);
    
    let reunion = cleanPath.join('/') + '/' + folder;

    return reunion
}

const formatNameFolder = (name) => {

    let pathname = name.split("/");

    let lastname = pathname.at(-1)

    return lastname
}

//location = path & index = name to add at path
const breadcrumbGoTo = (location,index) => {



    //separa per / e elimina il primo slash
    let formatLocation = location.pathname.split('/').splice(1);

    let oldValue = ''

    let prova = []

    formatLocation.map(el => {
        prova.push(oldValue + '/' + el );

        oldValue = oldValue + '/' + el;
    })

    let link;

    if(prova.length > 1){
        link = prova[index];
    } else {
        link = prova[0];
    }

    

    if(link.slice(-1) === '/'){
        return link
    } else {
        return link + '/'
    }

    
}

const getFileExtension = (file) => file.split(".").pop();

const removeFileExtension = (file) => {
    let split = file.split('.');
    split.pop();
    let finalName = split.join("."); 
    return finalName
}

const generateThumbnailPath = (key) => {
    let test = key.split('/')
    let test2 = test.pop()
    test.push('mf-tumbnail')
    test.push(test2)
    return test.join("/")
}


const getBucketByUrl = (path) => {
    let newPath = path

    if(path.slice(-1) === '/'){
        newPath = path.slice(0, -1)
    }

    let pathname = newPath.split("/"); 

    let cleanPath = pathname.splice(1)


    let reunion = cleanPath.join('/')
    let decodeReunion = decodeURI(reunion)
    return decodeReunion
}

const pathUrlManager = {
    fileNameCleaner,
    prefixGenerator,
    prefixGoTo,
    formatNameFolder,
    breadcrumbGoTo,
    getFileExtension,
    removeFileExtension,
    generateThumbnailPath,
    getBucketByUrl,
    clearFolder
};



  
export default pathUrlManager;